//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import HelloWorld from './components/HelloWorld.vue'
// import BookShelf from './components/BookShelf.vue'

export default {
  name: 'LayoutDefault',

  components: {
    // HelloWorld,
    // BookShelf
  },
  data () {
    return {
      appVersion: process.env.VUE_APP_VERSION || '0',
      querystring: {},
      leftDrawerOpen: false
    }
  },
  methods: {
    isActiveRoute(route) {
      if(!route) return window.location.hash === ""
      return window.location.hash.startsWith(`#!/${route}`);
    }
  },
}
